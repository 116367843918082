import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import ApiService from "./ApiService";

export default class EmailCollectionForm extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
        emailAddress: null,
        isSuccess: null,  // null means we havent tried
        isLoading: false,
        errorMessage: null,
      };
      this.onBtnClicked = this.onBtnClicked.bind(this);
    }

    onBtnClicked() {
      if (this.state.emailAddress === null || this.state.emailAddress === '') {
        this.setState({
          isSuccess: false,
          errorMessage: 'We need your email address!'
        })
        return;
      }
      this.setState({
        isLoading: true,
      }, function() {

        ApiService.emailListAdd(this.state.emailAddress)
          .then((response) => {
            this.setState({
              isLoading: false,
              isSuccess: true,
              errorMessage: null,
            });
          },(error) => {
            let msg;
            if (error.statusCode === 400) {
              msg = error.error.email_address[0];
            } else {
              msg = '😱  An unexpected error occured. Please try again'
            }
            this.setState({
              isLoading: false,
              isSuccess: false,
              errorMessage: msg,
            });
          }
        );
      })
    }

    render() {
      return (
        <div className="email-collection-wrapper">
          <div className="email-collection">
            <h3 className="header-label">{this.props.headerLabel}</h3>
            <p className="body-text">
              {this.props.bodyLabel}
            </p>
            <div className="input-and-btn">
              <input
                  className="form-control"
                  type="email"
                  placeholder="Enter your email"
                  value={this.state.emailAddress || ""}
                  onChange={(event) => {
                    this.setState({
                      emailAddress: event.target.value,
                      isSuccess: null,
                      errorMessage: null,
                    })
                  }}
                />
                <button
                  className="btn wannago-btn primary"
                  onClick={this.onBtnClicked}
                  disabled={this.state.isLoading}
                >
                  {this.state.isLoading === true ?
                    <div className="loader">Loading</div> :
                    this.props.btnLabel
                  }
                </button>
              </div>
              {this.state.isSuccess && (
                <p className="response-msg success">Nice! We’ve got you on the list 👍</p>
              )}
              {this.state.errorMessage && (
                <p className="response-msg error">{this.state.errorMessage}</p>
              )}
          </div>
        </div>
      )
    }
}

EmailCollectionForm.propTypes = {
    headerLabel: PropTypes.string.isRequired,
    bodyLabel: PropTypes.string.isRequired,
    btnLabel: PropTypes.string.isRequired,
};
