function makeEnum(arr){
  let obj = {};
  for (let val of arr){
      obj[val] = Symbol(val);
  }
  return Object.freeze(obj);
}

function getStaticImageUrl(name) {
  return `${STATIC_URL}images/${name}`
}

function showErrorMessage(msg) {
  // TODO use a better alert component
  alert(msg);
}

function showUnexpectedErrorMessage() {
  showErrorMessage('An unexpected error has occured :(  Please try again')
}

/**
 * Formats a utc iso datetime string into a specific format
 * @param datetimeStr
 * @param dateOptions to pass into date formatter
 * @returns {string}
 */
function formatDateStr(datetimeStr, dateOptions) {
  const date = new Date(datetimeStr);
  return date.toLocaleDateString('en-US', dateOptions)
}

function formatDateRange(startDatetimeStr, endDatetimeStr, hideTimeIfDifferentDays = false) {
  const start = new Date(startDatetimeStr);
  const end = new Date(endDatetimeStr);
  let options = null;

  if (start.toDateString() !== end.toDateString() && hideTimeIfDifferentDays) {
    options = { weekday: 'short', month: 'short', day: 'numeric'};
  } else {
    options = { weekday: 'short', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };
  }
  const fmt = new Intl.DateTimeFormat("en", options);
  return fmt.formatRange(start, end);
}

function openDirectionsPage(address) {
  const isIOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
  const addrUrlEncoded = encodeURI(address);
  if (isIOS) {
    // Should open apple maps
    window.open(`maps://maps.google.com/maps?daddr=${addrUrlEncoded}`);
  } else {
    window.open(`https://maps.google.com/maps?daddr=${addrUrlEncoded}`);
  }
}


function isOfferExpiredEvent(offer) {
  return offer.is_event && new Date() > new Date(offer.end_datetime)
}

function getQuantityAvailableLabel(quantityAvailable) {
  // quantityAvailable is either null (unlimited) or a number
  if (quantityAvailable === null) {
    return 'Limited availability'
  }else if (quantityAvailable > 0) {
    return quantityAvailable >= 10 ? `${quantityAvailable} available` : `Only ${quantityAvailable} left!`
  } else {
    return '0 available';
  }
}

function getBuyNowLabel(price, value) {
  let str = `$${price}`
  if (value > 0) {
    str += ` ($${value} value)`
  }
  return str;
}

export {
  makeEnum,
  getStaticImageUrl,
  showErrorMessage,
  showUnexpectedErrorMessage,
  openDirectionsPage,
  formatDateRange,
  isOfferExpiredEvent,
  formatDateStr,
  getQuantityAvailableLabel,
  getBuyNowLabel,
}
