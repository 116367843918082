import React from "react";
import ReactDOM from "react-dom";
import ApiService from "./ApiService";
import EmailCollectionForm from "./EmailCollectionForm";
import { getStaticImageUrl, formatDateRange } from "./utils";


class HomePage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      offers: null,
    };

    this.onFavoriteClicked = this.onFavoriteClicked.bind(this);
  }

  componentDidMount() {
    this.fetchAndDisplayOffers();
  }

  fetchAndDisplayOffers() {
    ApiService.getOffers()
      .then((response) => {
        this.setState({
          isLoaded: true,
          offers: response,
        });
      },(error) => {
        console.error(error);
        this.setState({
          isLoaded: true,
        });
      }
    );
  }

  _toggleFavoriteIcon(offerId) {
    let copiedOffers = [...this.state.offers]
    const foundIndex = copiedOffers.findIndex(x => x.id === offerId);
    copiedOffers[foundIndex].is_bookmarked = !copiedOffers[foundIndex].is_bookmarked;
    this.setState({
      offers: copiedOffers,
    });
  }

  onFavoriteClicked(offerId) {
    // If logged out, just take them to login page
    if (IS_AUTHENTICATED === false) {
      window.location = '/signup';
      return;
    }

    // First update the offer locally to toggle the icon immediately
    // so we dont have to wait for the server
    this._toggleFavoriteIcon(offerId);

    // Now make the request to the server
    ApiService.bookmarkOfferToggle(offerId)
      .then((response) => {
        // Get the new offer returned from the server (should have correct is_bookmarked)
        const updatedOffer = response.offer;

        // Clone the current offers, and replace the new offer in that list
        let copiedOffers = [...this.state.offers]
        const foundIndex = copiedOffers.findIndex(x => x.id === offerId);
        copiedOffers[foundIndex] = updatedOffer;
        this.setState({
          offers: copiedOffers,
        });
      },(error) => {
        // There was an error on the server, so revert back
        console.error(error);
        this._toggleFavoriteIcon(offerId);
      }
    );
  }

  _renderLoadingState() {
    // TODO better loading state
    return (
      <div></div>
    )
  }

  _renderErrorState() {
    // TODO better error state
    return (
      <p>Sorry, an error occurred.  Please try refreshing the page.</p>
    )
  }

  _renderOfferList() {
    let offerItems = this.state.offers.map((item, key) =>
      <HomePageOfferCard key={key} offer={item} onFavoriteClicked={this.onFavoriteClicked} />
    );
    return (
      <div>
        <div className="offer-list">
          {offerItems}
        </div>
      </div>
    )
  }

  render() {
    let mainContent;
    if (this.state.isLoaded === false) {
      mainContent = this._renderLoadingState();
    } else {
      if (this.state.offers === null) {
        // We couldn't load the offers for some reason
        mainContent = this._renderErrorState();
      } else {
        mainContent = this._renderOfferList();
      }
    }

    return (
      <div id="home-page">
        {IS_AUTHENTICATED === false &&
          <div className="header-wrapper">
            <h2 className={`header-text brand-${BRAND.name}`}>{BRAND.HOME_HEADER_TITLE}</h2>
            <p className="header-subtext">
              {BRAND.HOME_HEADER_PARAGRAPH}
            </p>
            <a href="/how-it-works" className="btn learn-more-btn">Learn More</a>
          </div>
        }

        {mainContent}

        {IS_AUTHENTICATED === false && this.state.isLoaded === true && (
          <EmailCollectionForm
            headerLabel="New Offers Added Regularly"
            bodyLabel="We keep things fresh by always adding new offers.  Be the first to hear about what’s new!"
            btnLabel="Get Updates"
          />
        )}
      </div>
    )
  }
}

class HomePageOfferCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onFavoriteClicked = this.onFavoriteClicked.bind(this);
  }

  onFavoriteClicked(e) {
    e.preventDefault();
    this.props.onFavoriteClicked(this.props.offer.id);
  }
  showRedeemedState() {
    // dont show for buy-it-nows
    return this.props.offer.is_redeemed === true && this.props.offer.price === "0.00"
  }
  render() {
    let imgUrl = getStaticImageUrl('placeholder-image.png');
    if (this.props.offer.images.length > 0) {
      imgUrl = this.props.offer.images[0].image;
    }
    const imgSectionStyle = {
      backgroundImage: `url(${imgUrl})`,
    };
    let cardClasses = "home-page-offer-card col-12 col-md-4";
    if (this.showRedeemedState()) {
      cardClasses += " redeemed"
    }
    return (
      <div className={cardClasses}>
        <a href={this.props.offer.url}>
          <div className="img-container" style={imgSectionStyle}>
            {/* Show inventory count if not redeemed */}
            {
              this.props.offer.is_redeemed === false &&
                this.props.offer.quantity_available !== null &&
                this.props.offer.quantity_available > 0 &&
                this.props.offer.quantity_available < 10 &&
              <span className="card-pill">Only {this.props.offer.quantity_available} left!</span>
            }

            {/* Show bookmark icon if not redeemed */}
            {
              this.props.offer.is_redeemed === false &&
              <button className="btn btn-link" onClick={this.onFavoriteClicked}>
                {
                  this.props.offer.is_bookmarked === true ?
                    <i className="fave-icon fas fa-heart"/> :
                    <i className="fave-icon far fa-heart"/>
                }
              </button>
            }

            {/* Show redeem overlay if redeemed */}
            {this.showRedeemedState() && <div className="redeemed-overlay in-card" />}
          </div>
          <div className="captions">
            <div className="first-line">
              <p className="offer-name">{this.props.offer.name}</p>
            </div>
            {(this.props.offer.vendor || this.props.offer.is_event) &&
              <div className="second-line">
                {this.props.offer.is_event && (
                  <>
                    <span className="second-line-txt">
                      {formatDateRange(this.props.offer.start_datetime, this.props.offer.end_datetime, true)}
                    </span>
                  </>
                )}
                {this.props.offer.vendor && <span className="second-line-txt">{this.props.offer.vendor.name}</span>}
              </div>
            }
          </div>
        </a>
      </div>
    )
  }
}

// Pass page data as component props
ReactDOM.render(
  <HomePage />,
  document.getElementById('home-react-comp')
);
